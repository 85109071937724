import axios from "axios";
import { host } from "../host";

export const getFlights = ({searchDetails, setSearchResult}) => {
    setSearchResult({ loading: true, data: [] })
    const cancelToken = axios.CancelToken.source()
    const url = `${host}/api/v1/seats/search_by_date`;

    axios.get(url, {
        timeout: 60000,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type, x-access-token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
            "Content-Type": "application/json",
        },
        params: { origin: searchDetails.origin, destination: searchDetails.destination, from: searchDetails.from, to: searchDetails.to }
    },
    {
        cancelToken: cancelToken.token
    })

    .then((response) => {
        console.log(response);
        if (Array.isArray(response.data)) {
            if (response.data.length === 0) {
                setSearchResult(p => { return { ...p, loading: false, data: [] } })
            } else {
                setSearchResult(p => { return { ...p, loading: false, data: response.data } })
            }
        }
    })
    
    .catch((err) => {
        if (axios.isCancel(err)) {
            console.log('Seats fetch cancelled!');
        } else {
            console.log(err);
        }
        setSearchResult(p => { return { ...p, loading: false, data: [] } })
    })

    .finally(() => {
        setSearchResult(p => { return { ...p, loading: false } })
    })
}