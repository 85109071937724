import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { host } from '../host'

const useFetchSeats = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        const getSeats = () => {
            setLoading(true)
            const cancelToken = axios.CancelToken.source()
            const url = `${host}/api/v1/seats`;
        
            axios.get(url, {
                timeout: 60000,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    "Content-Type": "application/json",
                }
            },
            {
                cancelToken: cancelToken.token
            })
            .then((response) => {
                setData(response.data);
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    console.log('Seats fetch cancelled!');
                } else {
                    console.log(err);
                }
                setData([])
            })
            .finally(() => {
                setLoading(false)
            })
        }

        getSeats()
        
        return () => {
            setLoading(false)
            setData([])
        }
    }, [])

    return [ loading, data ]
}

export default useFetchSeats