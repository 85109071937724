import React, { useState, useEffect } from 'react'
import { PiAirplaneTakeoffBold, PiAirplaneLandingBold } from 'react-icons/pi'
import { BsCalendarWeek } from 'react-icons/bs'
import { FaSearch } from 'react-icons/fa'
import { HiMenu } from 'react-icons/hi'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import useFetchSeats from '../hooks/useFetches/useFetchSeats'
import Footer from './Footer'
import { iso } from '../isoFinal'
import background from '../assets/background.jpg'
import fmfLogo from '../assets/fmfLogo.png'
import airplaneGlobe from '../assets/airplaneGlobe.svg'
import airplaneInfo from '../assets/airplaneInfo.svg'
import mobileApp from '../assets/mobileApp.png' 
import googleDownloadWhite from '../assets/googleDownloadWhite.png' 

const Home = ({ setNavSelected }) => {
    const navigate = useNavigate()

    const [airport, setAirport] = useState({ origin: [], destination: [], from: moment().format('YYYY-MM-DD'), to: moment().add(3, 'days').format('YYYY-MM-DD'), })
    const [formInput, setFormInput] = useState({ origin: '', destination: '' })
    const [airportList, setAirportList] = useState({ origin: [], destination: [] })
    const [finalAirportList, setFinalAirportList] = useState({ origin: [], destination: [] })
    const [showAirportList, setShowAirportList] = useState({ origin: false, destination: false })
    const [displayMenu, setDisplayMenu] = useState(false)
    const [changeNav, setChangeNav] = useState(false)
  
    const fetch = useFetchSeats()
    const [ loading, data ] = fetch

    useEffect(() => {
        setNavSelected('home')
    }, [])
    
    // check window scroll
    useEffect(() => {
        const handleChange = () => {
            const show = document.getElementById('scrollDiv').scrollTop
            if (show > 60) {
                setChangeNav(true)
            } else {
                setChangeNav(false)
            }
        }
        document.getElementById('scrollDiv').addEventListener('scroll', handleChange)
        return () => document.getElementById('scrollDiv').removeEventListener('scroll', handleChange)
    }, [])

    // after getting regions data set airport list
    useEffect(() => {
        if (data.length > 0) {
            let originAirports = data.filter( (ele, ind) => ind === data.findIndex( elem => elem.OriginAirport === ele.OriginAirport && elem.OriginRegion === ele.OriginRegion))
            let destinationAirports = data.filter( (ele, ind) => ind === data.findIndex( elem => elem.DestinationAirport === ele.DestinationAirport && elem.DestinationRegion === ele.DestinationRegion))
            let finalOriginAirports = []
            let finalDestinationAirports = []

            originAirports.map(e => {
                let filtered = iso.data.find(f => f.iata_code === e.OriginAirport)
                if (filtered) {
                    finalOriginAirports.push({ ...e, name: filtered.name ? filtered.name : '', municipality: filtered.municipality ? filtered.municipality : '' })
                }
            })
            destinationAirports.map(e => {
                let filtered = iso.data.find(f => f.iata_code === e.DestinationAirport)
                if (filtered) {
                    finalDestinationAirports.push({ ...e, name: filtered.name ? filtered.name : '', municipality: filtered.municipality ? filtered.municipality : '' })
                }
            })
            
            finalOriginAirports.sort((a, b) => a.OriginAirport.localeCompare(b.OriginAirport))
            finalDestinationAirports.sort((a, b) => a.DestinationAirport.localeCompare(b.DestinationAirport))

            setFinalAirportList({ origin: finalOriginAirports, destination: finalDestinationAirports })
            setAirportList({ origin: finalOriginAirports, destination: finalDestinationAirports })
        }
    }, [data])

    const handleOriginInput = e => {
        e.preventDefault()
        setFormInput(p => { return { ...p, origin: e.target.value } })
        setShowAirportList(p => { return { ...p, origin: true } })
        const value = e.target.value.toLowerCase()
        let temp = (value === '') ? finalAirportList.origin : finalAirportList.origin.filter(f => f.OriginAirport.toLowerCase().includes(value) || f.name.toLowerCase().includes(value) || f.municipality.toLowerCase().includes(value))
        setAirportList(p => { return { ...p, origin: temp.sort((a, b) => a.OriginAirport.localeCompare(b.OriginAirport)) } })
    }

    const handleOriginClick = e => {
        setFormInput(p => { return { ...p, origin: '' } })
        setAirport(p => {return{...p, origin: [e.OriginAirport]}})
        setShowAirportList(p => { return { ...p, origin: false } });
        setAirportList(p => { return { ...p, origin: finalAirportList.origin.sort((a, b) => a.OriginAirport.localeCompare(b.OriginAirport)) } })
    }
    
    const handleDestinationInput = e => {
        e.preventDefault()
        setFormInput(p => { return { ...p, destination: e.target.value } })
        setShowAirportList(p => { return { ...p, destination: true } })
        const value = e.target.value.toLowerCase()
        let temp = (value === '') ? finalAirportList.destination : finalAirportList.destination.filter(f => f.DestinationAirport.toLowerCase().includes(value) || f.name.toLowerCase().includes(value) || f.municipality.toLowerCase().includes(value))
        setAirportList(p => { return { ...p, destination: temp.sort((a, b) => a.DestinationAirport.localeCompare(b.DestinationAirport)) } })
    }

    const handleDestinationClick = e => {
        setFormInput(p => { return { ...p, destination: '' } })
        setAirport(p => {return{...p, destination: [e.DestinationAirport]}})
        setShowAirportList(p => { return { ...p, destination: false } });
        setAirportList(p => { return { ...p, destination: finalAirportList.destination.sort((a, b) => a.DestinationAirport.localeCompare(b.DestinationAirport)) } })
    }

    return (
        <div className='h-fit w-full bg-white flex flex-col'>
            {/* sticky navbar */}
            <div className={`${changeNav ? 'sticky bg-blue-600' : 'absolute bg-[rgba(0,0,0,0.3)] backdrop-blur-[1px]'} duration-200 top-0 h-[60px] z-50 w-full flex flex-col items-center`}>
                <div className='relative h-full w-full max-w-[1024px] py-[10px] px-[20px] flex items-center justify-between'>
                    <div className='h-[40px] flex gap-[10px] items-center cursor-pointer' onClick={() => document.getElementById('scrollDiv').scrollTo({ top: 0, behavior: 'smooth' })}>
                        <img src={fmfLogo} alt="logo" className='h-[40px] w-[40px] bg-white rounded-lg' />
                        <p className='text-white'>FMF.flights</p>
                    </div>
                    <div className='h-full flex items-center gap-x-[10px] xl:hidden'>
                        <div className='h-full flex items-center px-[10px] rounded-lg bg-white text-black text-sm duration-200'>Home</div>
                        <div className='h-full flex items-center px-[10px] rounded-lg text-white text-sm cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => navigate('/search')}>Search</div>
                        <div className='h-full flex items-center px-[10px] rounded-lg text-white text-sm cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => navigate('/about')}>About</div>
                        <div className='h-full flex items-center px-[10px] rounded-lg text-white text-sm cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => navigate('/contact')}>Contact</div>
                        <div className='h-full flex items-center px-[10px] rounded-lg text-white text-sm cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => navigate('/policy')}>Privacy Policy</div>
                        <div className='h-full flex items-center px-[10px] rounded-lg text-white text-sm cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => navigate('/terms')}>Terms & Conditions</div>
                    </div>
                    <div className='h-fit w-fit cursor-pointer hidden xl:block' onClick={() => setDisplayMenu(p => !p)}>
                        <HiMenu size={40} color={'#FFF'}/>
                    </div>
                    <div className={`absolute top-[100%] left-0 ${changeNav ? 'bg-blue-600' : 'bg-black backdrop-blur-[1px]'} h-fit w-full grid-flow-row gap-[10px] p-[20px] hidden xl:grid duration-200 origin-top ${displayMenu ? 'scale-y-100' : 'scale-y-0'}`}>
                        <div className='h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg bg-white text-black text-sm border border-[#FFF] duration-200' onClick={() => {setDisplayMenu(p => !p);}}>Home</div>
                        <div className='h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-white text-sm border border-[#FFF] cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => {setDisplayMenu(p => !p); navigate('/search');}}>Search</div>
                        <div className='h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-white text-sm border border-[#FFF] cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => {setDisplayMenu(p => !p); navigate('/about');}}>About</div>
                        <div className='h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-white text-sm border border-[#FFF] cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => {setDisplayMenu(p => !p); navigate('/contact');}}>Contact</div>
                        <div className='h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-white text-sm border border-[#FFF] cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => {setDisplayMenu(p => !p); navigate('/policy');}}>Privacy Policy</div>
                        <div className='h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-white text-sm border border-[#FFF] cursor-pointer duration-200 hover:bg-white hover:text-black' onClick={() => {setDisplayMenu(p => !p); navigate('/terms');}}>Terms & Conditions</div>
                    </div>
                </div>
            </div>

            {/* hero section */}
            <div className='relative h-fit min-h-[600px] w-full bg-blue-600 flex flex-col items-center'>
                <img src={background} alt="background" className='absolute h-full w-full object-cover' />
                <div className='z-10 flex-1 w-full max-w-[1024px] pt-[60px] pb-[40px] px-[20px] flex flex-col items-center justify-center gap-[10px]'>
                    <p className='text-3xl font-semibold text-white text-center leading-normal xl:text-2xl'>Search Flights with FMF.flights</p>
                    <div className='h-fit w-full bg-white rounded-xl py-[20px] px-[30px] flex flex-wrap items-center justify-center gap-[10px] lg:px-[20px]'>
                        <div className='h-fit w-full grid grid-cols-2 gap-[10px] xl:grid-cols-1'>
                            <div className='h-fit w-full grid grid-cols-2 gap-[10px] md:grid-cols-1'>
                                {/* origin */}
                                <div className='relative w-full h-fit p-[10px] border border-[#CCC] rounded-md'>
                                    <div className='font-medium flex items-center gap-x-1 leading-normal'>Origin<PiAirplaneTakeoffBold size={16} className='fill-blue-600'/></div>
                                    <div className='bg-white rounded-md flex flex-wrap gap-1'>
                                        <div className={`h-[30px] border-2 border-[#555] rounded-md px-1 text-[#555] ${(airport.origin.length === 0) ? 'hidden' : 'flex'} flex items-center`}>
                                            {airport.origin[0]}
                                        </div>
                                        <input type="text"
                                            className='h-[30px] flex-1 w-full min-w-[50px] border-b-2 border-b-[#CCC] outline-none'
                                            placeholder='Origin Airport'
                                            value={formInput.origin}
                                            onClick={e => { e.preventDefault(); setShowAirportList(p => { return { ...p, origin: !p.origin } }); }}
                                            onChange={e => handleOriginInput(e)}/>
                                    </div>
                                    {showAirportList.origin ? (
                                        <div className='absolute z-10 top-[100%] left-0 bg-white border-2 border-[#777] h-fit max-h-[200px] w-full p-[5px] rounded-md flex flex-col overflow-y-scroll'>
                                            {loading ? (
                                                <div className='flex items-center gap-[5px]'><div className='h-[20px] w-[20px] rounded-full border-[5px] border-[#CCC] border-t-[5px] border-t-[#555] animate-spin'/> Loading</div>
                                            ) : (
                                                airportList.origin.map((e, i) => (
                                                    <div key={i} onClick={() => handleOriginClick(e)} className={`w-full flex flex-row py-[5px] gap-[5px] border-b border-b-[#CCC] cursor-pointer hover:opacity-50 duration-200 bg-white`}>
                                                        <div className='w-[45px]'>
                                                            <div className='w-full border border-[#AAA] flex items-center justify-center rounded-md'>
                                                                {e.OriginAirport}
                                                            </div>
                                                        </div>
                                                        <div className='flex-1'>{e.name}, {e.municipality}</div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    ) : <></>}
                                </div>

                                {/* destination */}
                                <div className='relative w-full h-fit p-[10px] border border-[#CCC] rounded-md'>
                                    <div className='font-medium flex items-center gap-x-1 leading-normal'>Destination<PiAirplaneLandingBold size={16} className='fill-blue-600'/></div>
                                    <div className='bg-white rounded-md flex flex-wrap gap-1'>
                                        <div className={`h-[30px] border-2 border-[#555] rounded-md px-1 text-[#555] ${(airport.destination.length === 0) ? 'hidden' : 'flex'} flex items-center`}>
                                            {airport.destination[0]}
                                        </div>
                                        <input type="text"
                                            className='h-[30px] flex-1 w-full min-w-[50px] border-b-2 border-b-[#CCC] outline-none'
                                            placeholder='Destination Airport'
                                            value={formInput.destination}
                                            onClick={e => { e.preventDefault(); setShowAirportList(p => { return { ...p, destination: !p.destination } }); }}
                                            onChange={e => handleDestinationInput(e)}/>
                                    </div>
                                    {showAirportList.destination ? (
                                        <div className='absolute z-10 top-[100%] left-0 bg-white border-2 border-[#777] h-fit max-h-[200px] w-full p-[5px] rounded-md flex flex-col overflow-y-scroll'>
                                            {loading ? (
                                                <div className='flex items-center gap-[5px]'><div className='h-[20px] w-[20px] rounded-full border-[5px] border-[#CCC] border-t-[5px] border-t-[#555] animate-spin'/> Loading</div>
                                            ) : (
                                                airportList.destination.map((e, i) => (
                                                    <div key={i} onClick={() => handleDestinationClick(e)} className={`w-full flex flex-row py-[5px] gap-[5px] border-b border-b-[#CCC] cursor-pointer hover:opacity-50 duration-200 bg-white`}>
                                                        <div className='w-[45px]'>
                                                            <div className='w-full border border-[#AAA] flex items-center justify-center rounded-md'>
                                                                {e.DestinationAirport}
                                                            </div>
                                                        </div>
                                                        <div className='flex-1'>{e.name}, {e.municipality}</div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    ) : <></>}
                                </div>
                            </div>

                            <div className='h-fit w-full p-[10px] border border-[#CCC] rounded-md'>
                                <div className='font-medium flex items-center gap-x-1'>One way flights between <BsCalendarWeek size={16} className='fill-blue-600'/></div>
                                <div className='h-fit w-full grid grid-cols-2 gap-[10px] md:grid-cols-1'>
                                    {/* from */}
                                    <div className='relative w-full h-fit'>
                                        <div className='bg-white rounded-md flex flex-wrap gap-1'>
                                            <input type="date"
                                                className='h-[30px] flex-1 w-full min-w-[100px] border-2 border-[#CCC] outline-none rounded-md'
                                                value={airport.from}
                                                min={moment().format('YYYY-MM-DD')}
                                                onChange={e => {
                                                    setShowAirportList(p => { return { ...p, origin: false, destination: false }})
                                                    if (new Date(airport.to) >= new Date(e.target.value)) {
                                                        setAirport(p => { return { ...p, from: e.target.value } })
                                                    } else (
                                                        setAirport(p => { return { ...p, from: e.target.value, to: e.target.value } })
                                                    )
                                                }}/>
                                        </div>
                                    </div>

                                    {/* to */}
                                    <div className='relative w-full h-fit'>
                                        <div className='bg-white rounded-md flex flex-wrap gap-1'>
                                            <input type="date"
                                                className='h-[30px] flex-1 w-full min-w-[100px] border-2 border-[#CCC] outline-none rounded-md'
                                                value={airport.to}
                                                min={airport.to}
                                                onChange={e => {
                                                    setShowAirportList(p => { return { ...p, origin: false, destination: false }})
                                                    setAirport(p => { return { ...p, to: e.target.value } })
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        {/* search button */}
                        <div className='h-[60px] w-full max-w-[300px] p-[10px] bg-blue-600 rounded-md flex items-center justify-center gap-[5px] cursor-pointer duration-200 hover:opacity-50 md:max-w-none' onClick={() => ((airport.origin.length === 0) || (airport.destination.length === 0)) ? window.alert('Please select Origin and Destination to find flights.') : navigate('/search', { state: airport })}>
                            <FaSearch size={16} color='#FFF'/>
                            <p className='text-white'>FIND FLIGHTS</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* features section */}
            <div id='features-section' className='h-fit w-full bg-white flex items-center justify-center py-[40px] px-[20px]'>
                <div className='relative h-full w-full max-w-[1024px] bg-gradient-to-bl from-cyan-100 via-cyan-50 to-cyan-100 p-[20px] rounded-3xl grid grid-cols-2 gap-x-[20px] lg:grid-cols-1'>
                    <div className='z-10 h-full w-full flex flex-col items-start justify-center lg:items-center'>
                        <p className='text-3xl font-semibold text-black leading-normal xl:text-2xl lg:hidden'>Our services</p>
                        <div className='flex-wrap items-center gap-[10px] hidden lg:flex'>
                            <p className='text-2xl font-semibold text-black leading-normal'>Our services</p>
                            <img src={airplaneGlobe} alt="airplaneGlobe" className='w-full max-w-[30px] xl:text-sm' />
                        </div>
                        <div className='h-fit flex flex-col gap-[10px]'>
                            <div className='h-fit w-full'>
                                <p className='font-medium text-black leading-normal text-justify underline underline-offset-2'>Search for Available Seats on Miles Programs:</p>
                                <p className='text-[#555] leading-normal text-justify lg:text-sm'>Easily find flights with open seats using your Airlines Miles programs.</p>
                            </div>
                            <div className='h-fit w-full'>
                                <p className='font-medium text-black leading-normal text-justify underline underline-offset-2'>City-to-City or City-to-Region:</p>
                                <p className='text-[#555] leading-normal text-justify lg:text-sm'>Customize your search for tailored travel experiences.</p>
                            </div>
                            <div className='h-fit w-full'>
                                <p className='font-medium text-black leading-normal text-justify underline underline-offset-2'>Upgrade to Business Class:</p>
                                <p className='text-[#555] leading-normal text-justify lg:text-sm'>Unlock the world of luxury travel without breaking the bank.</p>
                            </div>
                            <div className='h-fit w-full'>
                                <p className='font-medium text-black leading-normal text-justify underline underline-offset-2'>Savings Up to 100%:</p>
                                <p className='text-[#555] leading-normal text-justify lg:text-sm'>Maximize your Airlines Miles to save big on your travel expenses.</p>
                            </div>
                            <div className='h-fit w-full'>
                                <p className='font-medium text-black leading-normal text-justify underline underline-offset-2'>Plan Your Getaway:</p>
                                <p className='text-[#555] leading-normal text-justify lg:text-sm'>Seamlessly plan your dream trips with precision and ease.</p>
                            </div>
                        </div>
                    </div>
                    <div className='h-full w-full flex items-center justify-center lg:hidden'>
                        <img src={airplaneGlobe} alt="airplaneGlobe" className='w-full' />
                    </div>
                </div>
            </div>

            {/* info section */}
            <div id='info-section' className='h-fit w-full flex items-center justify-center pb-[40px] px-[20px]'>
                <div className='relative h-full w-full max-w-[1024px] bg-gradient-to-bl from-teal-100 via-teal-50 to-teal-100 p-[20px] rounded-3xl grid grid-cols-2 gap-x-[20px] lg:grid-cols-1'>
                    <div className='h-full w-full flex items-center justify-center lg:hidden'>
                        <img src={airplaneInfo} alt="airplaneInfo" className='w-full' />
                    </div>
                    <div className='z-10 h-full w-full flex flex-col items-start justify-center lg:items-center'>
                        <p className='text-3xl font-semibold text-black leading-normal xl:text-2xl lg:hidden'>How to fly Business class at Discounted rates?</p>
                        <p className='text-3xl font-semibold text-black text-center leading-normal xl:text-2xl hidden lg:inline'>How to fly Business class at Discounted rates? <span className='inline text-blue-600'>&#9432;</span></p>
                        <ul className='list-decimal list-inside'>
                            <li className='text-[#555] leading-normal text-justify mb-[10px] lg:text-sm'>Search for your destination and dates. Suggestion select maximum date range 90 days ahead. You can search for "city to city" or "city to region" if you want to find new destinations.</li>
                            <li className='text-[#555] leading-normal text-justify mb-[10px] lg:text-sm'>Click "FIND FLIGHTS". It might take 30-60 seconds to scan the best offers.</li>
                            <li className='text-[#555] leading-normal text-justify mb-[10px] lg:text-sm'>From the list of available flights, check the points needed to get the flights and how much you are saving if buying with miles.</li>
                            <li className='text-[#555] leading-normal text-justify mb-[10px] lg:text-sm'>Go to the page of the airline and buy the necessary miles.</li>
                            <li className='text-[#555] leading-normal text-justify mb-[10px] lg:text-sm'>Purchase the flight from the airline using the miles.</li>
                            <li className='text-[#555] leading-normal text-justify mb-[10px] lg:text-sm'>Congratulations, you saved money.</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* app download */}
            <div id='app-download-section' className='h-fit w-full flex items-center justify-center pb-[40px] px-[20px]'>
                <div className='relative h-full w-full max-w-[1024px] bg-gradient-to-bl from-blue-600 via-blue-500 to-blue-600 p-[20px] grid grid-cols-2 gap-[20px] rounded-3xl lg:grid-cols-1'>
                    <div className='h-full w-full flex items-center justify-center'>
                        <img src={mobileApp} alt="airplaneInfo" className='h-full max-h-[500px] lg:max-w-[200px]' />
                    </div>
                    <div className='z-10 h-full w-full flex flex-col items-start justify-center lg:items-center'>
                        <p className='text-3xl font-semibold text-white text-center leading-normal xl:text-2xl'>Get the FMF.flights App</p>
                        <p className='text-[#EEE] leading-normal text-justify mb-[10px] lg:text-sm lg:text-center'>Use the app for better accessibility, experience and additional in-app features.</p>
                        <img src={googleDownloadWhite} alt="googleDownloadWhite" className='w-full max-w-[200px] cursor-pointer duration-200 hover:opacity-50' onClick={() => window.open('https://play.google.com/store/apps/details?id=com.betelgues.fmf', '_blank')} />
                    </div>
                </div>
            </div>

            {/* footer */}
            <Footer/>
        </div>
    )
}

export default Home